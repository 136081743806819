import styled from "styled-components";
import { Link } from "react-router-dom";

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;
const Text = styled.div`
  color: white;
  position: absolute;
  top: 0;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background-color: rgba(0,0,0,.8);
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  text-decoration: none;
  text-align: center;
  padding: 20px;
  line-height: 1.5em;
`;
const Thumb = styled.div`
  background-image: url(${props => props.src });
  width: 200px;
  height: 132px;
  background-size: cover;
  background-position: 50%;
  background-color: #282828;

  @media (max-width: 800px) {
    width: 150px;
    height: 100px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  @media (min-width: 800px) {
    &:hover ${Text} { display: flex; }
  }
`;

function ThumbnailList({ list }) {
  return (
    <ListWrapper>
      { list.map((d,i) => {
        return <>
        {d.projects.map((e,j) => {
          if (e.link && !e.skipThumb)
            return (
              <Link to={e.link} key={`img${j}`}>
                <Wrapper>
                  <Thumb src={`${process.env.PUBLIC_URL}/${e.link}/thumbnail.jpg`} />
                  <Text>{e.title}</Text>
                </Wrapper>
              </Link>
            );
          else
            return null;
        }
        )}
        </>;
      })}
    </ListWrapper>
  );
}

export default ThumbnailList;
