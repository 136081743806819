import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

import HamburgerMenu from "./HamburgerMenu";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 40px;
  height: 54px;
  a {
    font-family: 'Quicksand';
    font-weight: bold;
    font-size: 1.25em;
    margin-left: 30px;
    color: white;
    text-decoration: none;
    padding: 10px 0;
    &.active { font-weight: bold; }
  }
  @media (max-width: 800px) {
    padding: 0 20px;
    a { color: black; }
  }
`;
const Logo = styled.div`
  background-image: url(${process.env.PUBLIC_URL}/favicon.png);
  width: 36px;
  height: 36px;
  background-size: cover;
  flex-shrink: 0;
  margin-right: 10px;
`;
const Nav = styled(NavLink)`
  &:hover {
    text-decoration: underline dotted;
    text-decoration-color: #ff0;
  }
`;

function NavBar(props) {
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const debouncedWindow = useDebounce(windowWidth, 200);
  const [navStyle, setNavStyle] = useState("list");

  useEffect(() => {
    if (windowWidth < 800)
      setNavStyle("hamburger")
    else
      setNavStyle("list");
    // eslint-disable-next-line
  }, [debouncedWindow]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
  });

  return (
    <Wrapper>
      <Link to="/" style={{ display: "flex", alignItems: "center", flex: 1, margin: 0 }}>
        <Logo />
      </Link>
      {navStyle === "list" ? (
        <NavLinks />
      ) : (
        <HamburgerMenu />
      )}
    </Wrapper>
  );
}

function useDebounce(value, delay) {
  // State and setters for debounced value
  // https://usehooks.com/useDebounce/
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export const NavLinks = () => (
  <>
    <Nav exact to={`/`}>Portfolio</Nav>
    <Nav to={`/resume`}>Resume</Nav>
    <Nav to={`/talks`}>Talks</Nav>
    <Nav to={`/press`}>Press</Nav>
    <Nav to={`/about`}>About</Nav>
  </>
);

export default NavBar;
