import styled from "styled-components";

const Wrapper = styled.div`
  width: 800px;
  height: 800px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(-25deg, #e66465, #9198e5);
  position: relative;
`;
const Title = styled.div`
  font-size: 88px;
  font-weight: bold;

    position: absolute;
    left: 8%;
    top: 10%;
    transform: translate(-50%, -50%);
    display: block;
    color: #CD748B;
    letter-spacing: 2px;
    transform: rotate(-10deg);
    text-align: center;

  &::after {
    content: "Marfafest '22";
    position: absolute;
    color: transparent;
    background-image: repeating-linear-gradient(
      45deg,
      transparent 0,
      transparent 3px,
      white 3px,
      white 6px
    );
    -webkit-background-clip: text;
    top: -5px;
    left: -5px;
    z-index: 1;
    transition: 1s;
  }
`;
const TextWrapper = styled.div`
  position: absolute;
  left: 10%;
  top: 33%;
  width: 80%;

  h1 { font-size: 3em; }
`;
const Footer = styled.div`
  text-align: center;
  transform: skew(-30deg);
  letter-spacing: 8px;
  margin-top: 85%;
`;

function Fest() {

  return (
    <Wrapper>
      <Title>Marfafest '22</Title>
      <TextWrapper>
        <h1>I'll be in Marfa!</h1>
        <i>maybe you should too</i>
        <h1 style={{ textAlign: "right", marginTop: "120px" }}>Sept 22-25</h1>
        <p style={{ textAlign: "right", fontStyle: "italic" }}>or, arrive a few days early / stay a few days late</p>
      </TextWrapper>
      <Footer>come visit us before we secede</Footer>
    </Wrapper>
  );
}

export default Fest;
