import { useState, useEffect } from "react";

function Arrows1() {
  const [groups, setGroups] = useState([]);

  const size = 700;

  useEffect(() =>{
    let newGroups = [];
    // const arrows = ["↓", "↧", "↡", "↨", "↯", "↴", "↶", "⇂", "⇅", "⇊", "⇓", "⇕", "⇟", "⇣", "⇩", "⇳", "⤈", "⤋", "⤓", "⤵", "⥍", "⥝", "⥯", "⥥"]
    const rightArrwos = ["⇸", "↛", "↝", "⤘", "↠", "↣", "⤚", "↦", "↪", "↬", "⇨",  "⇀", "⇉", "⇌", "↘", "⇏", "⤠", "⇒",  "⇝", "⟶", "⇢", "⇥",  "→","↳", "⇲", "⥱", "⇴", "⇛", "⇶", "⤁","↷", "⥛",  "⤏", "⤥", "⇰", "⟹", "⇻", "⤕", "⇾", "⟴", "⤰", "⥱", "⤀", "⤃", "↳", "⤅", "⤇", "⤍", "⥵", "⤐", "⥓", "⤑", "⤔", "⤨", "⤖", "⤞", "↻", "⤳", "⤷", "⤼", "⟼", "⤿", "⥂", "⥅", "⥇", "⟾", "⥋", "⥤", "⥬"];
    const num = rightArrwos.length;
    for (let i = 0; i < num; i++) {

      // archemedian
      // const iter = scaleLinear().domain([0, 60, num]).range([10, 2, .1])

      // newGroups.push({
      //   x: Math.cos(Math.PI*6/num * (iter(i)+i)) * (30 + i * 6),
      //   y: Math.sin(Math.PI*6/num * (iter(i)+i)) * (30 + i * 6),
      //   rotate: (Math.PI*6/num * (iter(i)+i))*180/Math.PI + 90,
      //   arrow: rightArrwos[i%rightArrwos.length],
      //   size: 40
      // })

      console.log(rightArrwos.length)

      const ring1 = 6;
      const ring2 = 14;
      const ring3 = 21;

      function getNum(i) {
        if (i < ring1)
          return ring1;
        else if (i < (ring1 + ring2))
          return ring2;
        else if (i < (ring1 + ring2 + ring3))
          return ring3;
        else
          return rightArrwos.length - ring1 - ring2 - ring3;
      }

      function getRadius(i) {
        if (i < ring1)
          return ring1 - 2;
        else if (i < (ring1 + ring2))
          return ring2 - 2;
        else if (i < (ring1 + ring2 + ring3))
          return ring3 - .5;
        else
          return rightArrwos.length - ring1 - ring2 - ring3 + 1;
      }

      const angle = (Math.PI*2/getNum(i) * i) ;

      newGroups.push({
        x: Math.cos(angle) * getRadius(i)*10,
        y: Math.sin(angle) * getRadius(i)*10,
        // rotate: angle * 180/Math.PI + 95,
        rotate: angle * 180/Math.PI + 30,
        arrow: rightArrwos[i%rightArrwos.length],
        size: 40
      })
    }
    setGroups(newGroups);
  }, []);
  //

  return (
    <div>
      <svg width={1200} height={size} style={{ overflow: "visible" }}>
        <g transform={`translate(${size/2}, ${size/2})`}>
        { groups.map((g,i) => (
          <g key={`id${i}`} transform={`translate(${g.x}, ${g.y})`}>
            <text textAnchor="middle" transform={`rotate(${g.rotate}, 0, 0)`} fontSize={g.size} fill="white">{g.arrow}</text>
          </g>
        ))}
        </g>
      </svg>
    </div>
  );
}

export default Arrows1;
