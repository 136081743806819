import styled from "styled-components";

import twitterSVG from "./twitter.svg";
import instaSVG from "./instagram.svg";

const Flex = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 80px;
  flex-direction: column;
  width: 550px;
  > div { margin-bottom: 40px; }
  > div:last-child { margin: 0; }

  @media (max-width: 800px) {
    width: 100%;
    padding: 0 0 20px 0;
  }
`;
const Header = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  font-family: 'Quicksand';
`;
const TwitterIcon = styled.a`
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: cover;
  mask-size: cover;
  mask-position: 50%;
  background-color: white;
  mask-image: url(${twitterSVG});
  margin-right: 20px;
`;
const InstaIcon = styled.a`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: cover;
  mask-size: cover;
  mask-position: 50%;
  background-color: white;
  mask-image: url(${instaSVG});
`;
const SelfPortrait = styled.div`
  height: 650px;
  width: 433px;
  background-image: url(${process.env.PUBLIC_URL}/portrait.jpg);
  background-size: cover;
  flex-shrink: 0;

  @media (max-width: 500px) {
    width: 300px;
    height: 450px;
  }
`;

function About() {
  return (
    <Flex>
      <SelfPortrait />
      <TextWrapper>
        <Header>hi, i'm rachel binx</Header>
        <div>you can contact me rachelbinx @ gmail</div>
        <div>
          <b>bio</b>
          <p style={{ fontSize: ".8em", margin: "5px 0 0" }}>Rachel Binx is a creative technologist, with a background in data visualization, art history, and interaction design. Her work explores the emotional, cultural, and aesthetic relationships between geo/location data and the physical world. Rachel has created a multitude of small companies to explore these ideas, building co-creation interfaces that transform a user's data into a custom-made object.</p>
        </div>
        <div>
          <TwitterIcon href="https://twitter.com/rachelbinx" />
          <InstaIcon href="https://instagram.com/rachelbinx" />
        </div>
      </TextWrapper>
    </Flex>
  );
}

export default About;
