import styled from "styled-components";
import { Link } from "react-router-dom";

import ThumbnailList from "./ThumbnailList";

/*
  .title {
    color: black;
    margin-bottom: 6px;
    font-size: 1.25em;
    transform: skew(-20deg);
    display: inline-block;
    background: #ff0; 
    padding: 1px 9px;
    font-weight: bold;
    font-family: 'Quicksand';
    span {
      transform: skew(20deg);
      display: block;
      white-space: nowrap;
    }
  }


  .name, .link {
      transform: skew(-20deg);
      display: inline-block;
      background: white; 
      color: black;
      padding: 1px 7px;
    }
    .name { background-color: #222; color: white; }
    
    .name span, .link a {
      display: block;
      transform: skew(20deg);
    }

    .link {
      a {
        color: black;
        text-decoration: none;
      }
      &:hover {
        transform: skew(20deg);
        background: #ff0;
        a {
          transform: skew(-20deg);
        }
      }
    }
*/

const Wrapper = styled.div`
  margin-bottom: 30px;
  min-width: 300px;
  &:last-child { margin-bottom: 0; }

  .title {
    color: white;
    margin-bottom: 6px;
    font-size: 1.25em;
    font-weight: bold;
    font-family: 'Quicksand';
  }
  .project {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-size: 1.05em;
    white-space: nowrap;

    a {
      color: white;
      text-decoration-color: #888;
    }

    .siteLink {
      text-decoration: none;
      margin-left: 4px;
      display: inline-block;
      color: white;
      padding: 1px 4px;
      font-size: 23px;
      line-height: 1.05em;
      &:hover { color: #fdeb6e; }
      margin-bottom: -4px;
      @media (max-width: 800px) {
        font-size: 28px;
      }
    }
    
    @media (max-width: 800px) {
      &:last-child { margin-bottom: 50px; }
    }
  }
  @media (max-width: 800px) {
    min-width: auto;
  }
`;
const Flex = styled.div`
  display: flex;
  flex-align: center;
  justify-content: space-between;
`;
const Time = styled.div`
  color: #ff0;
`;
const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${props => props.color};
`;

const LinkList = ({ list, tags }) => {
  const isNarrow = window.innerWidth < 800;
  return (
    <div>
      {list.map((p,i) => (
        <Wrapper key={`list${i}`}>
          <Flex>
            <div className="title">
              <span>
                {p.title}
              </span>
            </div>
            {p.time && <Time>{p.time}</Time>}
          </Flex>
          { isNarrow && 
            <div style={{ margin: "20px 0" }}>
              <ThumbnailList list={[p]} />
            </div>
          }
          {p.projects.map((n,j) => {
            return (
              <div key={`p${j}`} className="project">
                {n.tags.map((t,k) => {
                  const color = tags.find(tag => tag.tag === t).color;
                  return <Dot key={`tag${k}`} color={color} />;
                })}
                {n.link ? (
                  <span className="link">
                    <Link to={`/${n.link}`}>{n.title}</Link>
                  </span>
                ) : (
                  <span className="name">
                    <span>{n.title}</span>
                  </span>
                )}
                {n.siteLink && (
                  <a href={n.siteLink} className="siteLink">➠</a>
                )}
              </div>
            );
          })}
        </Wrapper>
      ))}
    </div>
  );
}

export default LinkList;
