import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ReactMarkdown from 'react-markdown';
import ReactPlayer from 'react-player';

const Title = styled.div`
  color: black;
  margin-bottom: 6px;
  display: block;
  font-size: 2em;
  transform: skew(-20deg);
  display: inline-block;
  background: #fff; 
  padding: 1px 15px;
  font-weight: bold;
  font-family: 'Quicksand';
  span {
    transform: skew(20deg);
    display: block;
  }
  @media (max-width: 600px) {
    font-size: 1.5em;
  }
`;
const Date = styled.div`
  color: #aaa;
  margin-top: 10px;
`;
const Description  = styled.div`
  max-width: 600px;
  a { color: white; }
`;
const Flex = styled.div`
  margin-top: 40px;
  max-width: 1000px;
`;
const Photo = styled.img`
  width: auto;
  max-width: 100%;
  margin: 0 20px 20px 0;
`;
const VideoWrapper = styled.div`
  position: relative;
  max-width: 1000px;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

function ProjectPage({ path }) {
  const { name } = useParams();

  const [markdown, setMarkdown] = useState();
  const [config, setConfig] = useState([]);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/${path}/${name}/writeup.md`)
      .then((response) => response.text())
      .then((text) => setMarkdown(text));

    fetch(`${process.env.PUBLIC_URL}/${path}/${name}/config.json`)
      .then((response) => response.json())
      .then((json) => setConfig(json));      
  }, [path, name]);

  if (!config) return;

  return (
    <>
      <Title>
        <span>{config.title}</span>
      </Title>
      { config.date && <Date>{config.date}</Date>}
      <Description>
        <ReactMarkdown children={markdown} />
      </Description>
      { config.videos && config.videos.map((v,i) => {
        let styleObj = {};
        if (v.width < 800) {
          // small viddy
          styleObj = { width: v.width, paddingTop: v.height }
        } else if (window.innerWidth < 1000) {
          styleObj = { paddingTop: `${100/(v.width/v.height)}%` }
        } else {
          styleObj = { paddingTop: `${1/(v.width/v.height) * 1000}px` }
        }

        return (
          <VideoWrapper key={`video${i}`} style={styleObj}>
            <ReactPlayer
              className='react-player'
              url={v.url}
              width='100%'
              height='100%'
            />
          </VideoWrapper>
        );
      })}
      <Flex>
        { config.photos && config.photos.map((p,i) => (
          <Photo key={`photo${i}`} src={`${process.env.PUBLIC_URL}/${path}/${name}/${p}`} />
        ))}
      </Flex>
    </>
  );
}

export default ProjectPage;
