import styled from "styled-components";

const TagWrapper = styled.div`
  border-radius: 12px;
  border: 1px solid ${props => props.color};
  padding: 4px 10px 6px;
  display: inline-block;
  margin: 0 20px 10px 0;

  > span { cursor: pointer; }
  > input {
    margin-right: 10px;
    transform: scale(1.2);
    cursor: pointer;
  }
  @media (max-width: 800px) {
    padding: 2px 10px 4px 8px !important;
    > input { margin-right: 10px; }
    font-size: .9em;
  }
`;

function TagList({ tags, displayTags, updateTag, setTags }) {
  return (
    <div style={{ marginBottom: "40px" }}>
      {tags.map((t,i) => (
        <TagWrapper key={`tag${i}`} color={t.color}>
          <input
            type="checkbox"
            style={{ accentColor: t.color }}
            checked={displayTags.indexOf(t.tag) !== -1}
            onChange={() => updateTag(t.tag)}
          />
          <span onClick={() => {
            if (displayTags.length === 1 && displayTags[0] === t.tag)
              setTags(tags.map(u => u.tag));
            else setTags([t.tag]);
          }}>
            {t.tag.split("-").join(" ")}
          </span>
        </TagWrapper>
      ))}
    </div> 
  );
}

export default TagList;
