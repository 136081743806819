import PageWrapper from "./PageWrapper";
import LinkList from "./LinkList";

function ProductCompanies() {
  const projects = [
    {
      title: "Product Companies",
      projects: [
        { title: "↬ Unicode Arrows ↫", siteLink: "https://unicodearrows.com", link: "unicode-arrows" },
        { title: "Postcard Gifts", siteLink: "https://postcardgifts.com", link: "postcards" },
        { title: "Meshu", link: "meshu" },
        { title: "Gifpop", siteLink: "https://gifpop.io/", link: "gifpop" },
        { title: "Monochōme", link: "monochome" },
        { title: "GPX Jewelry", link: "gpx-jewelry" },
        { title: "ManyMaps", link: "manymaps" },
        { title: "Cliffs & Coasts", link: "cliffs-coasts" },
        { title: "Meshu Print Maps", link: "meshu-print" },
        { title: "TrekNotes", link: "treknotes" }
      ]
    },
    {
      title: "Opensource Ecommerce Framework",
      projects: [
        { title: "Beluga", siteLink: "http://belugajs.com/", link: "beluga" }
      ]
    },
    {
      title: "Community Evangalism",
      projects: [
        { title: "Making Care of Business", siteLink: "https://makingcareof.biz/", link: "mcob" },
        { title: "Gifpop Artists", "siteLink": "https://gifpop.io/collections/artists", link: "gifpop-artists" }
      ]
    },
  ];

  return (
    <PageWrapper>
      <LinkList list={projects} path="product" />
    </PageWrapper>
  );
}

export default ProductCompanies;
