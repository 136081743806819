import { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";

const videoList = [
  { file: "IMG_0771", description: "Mammoth Lakes, California" },
  { file: "IMG_1837", description: "Bosque del Apache, New Mexico" },
  { file: "IMG_2270", description: "The Salton Sea, California" },
  { file: "lake", description: "Richmond, California" },
  { file: "IMG_2017", description: "Guadalupe Mountains, Texas" },
  { file: "IMG_1108", description: "Point Reyes, California" },
  { file: "IMG_8619", description: "The Mississippi River" },
  { file: "IMG_9239", description: "Marin Headlands, California" },
  { file: "IMG_1972", description: "White Sands, New Mexico" }
];

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 10px solid white;
  border-radius: calc((100vh - 150px) * 0.30);
  width: calc((100vh - 150px) * 0.5625);
  height: calc(100vh - 150px);
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .caption {
    position: absolute;
    font-family: 'Quicksand';
    font-weight: bold;
    font-size: 2em;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    height: 100%;
    z-index: 1;
    background: rgba(0,0,0,.5);
    align-items: center;
    justify-content: center;
    text-align: center;
    display: none;
    padding: 0 20px;
  }
  &:hover {
    .caption { display: flex; }
  }
`;

function Videos() {

  const videoRef = useRef();
  const randIndex = Math.floor(Math.random()*videoList.length);
  const [videoSrcIndex, setVideoSrcIndex] = useState(randIndex);

  const handleVideo = useCallback(() => {
    setVideoSrcIndex((videoSrcIndex + 1)%videoList.length);
    videoRef.current.play()
  }, [videoSrcIndex])

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    video.addEventListener('ended', handleVideo)

    return () => {
      video.removeEventListener('ended', handleVideo)
    }
  }, [handleVideo])

  if (window.innerWidth < 800) return(null);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Wrapper>
        <video autoPlay muted playsInline 
          ref={videoRef}
          src={`${process.env.PUBLIC_URL}/videos/${videoList[videoSrcIndex].file}.mp4`}
        />
        <video autoPlay muted playsInline 
          src={`${process.env.PUBLIC_URL}/videos/${videoList[(videoSrcIndex + 1)%videoList.length].file}.mp4`}
        />
        <div className="caption">{videoList[videoSrcIndex].description}</div>
      </Wrapper>
    </div>
  );
}

export default Videos;
