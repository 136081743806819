import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto 60px;
  flex-wrap: wrap;
`;
const Stage = styled.div`
  font-weight: bold;
  font-size: 32px;
  font-family: 'Quicksand';
  @media (min-width: 1000px) {
    &:after {
      content: "➤";
      color: #aaa;
      padding-left: 40px;
      vertical-align: top;
      line-height: 40px;
      font-size: 22px;
    }
    &:last-child:after { content: ""; }
  }
  @media (max-width: 700px) {
    font-size: 26px; 
  }
  &.selected { color: #ff0; }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(580px, 1fr));
  grid-gap: 40px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  > div {
    p { flex: 1; color: #ccc; }
    display: flex;
    flex-direction: column;
  }
`;
const Intro = styled.div`
  max-width: 600px;
  margin: 0 auto 40px;
`;
const Title = styled.h2`
  font-family: 'Quicksand';
  margin-bottom: 0;
`;
const BookList = styled.div`
  display: flex;
  flex-start: end;
  flex-wrap: wrap;
`;
const BookCover = styled.img`
  height: 250px;
  margin-right: 20px;
  @media (max-width: 500px) {
    height: 200px;
  }
`;

function ClimateReading() {
  const stages = ["Shock", "Anger", "Grief", "Acceptance", "Integration"];

  return (
    <div>
      <Flex>
        {stages.map((s,i) => (
          <Stage key={`s${i}`} className={i === stages.length - 1 ? "selected" : undefined}>
            {s}
          </Stage>
        ))}
      </Flex>
      <Intro>
        <p>Below is my Climate Reading List, the set of books I've gone through on my journey to understand and process climate change. I started reading these in 2019, and has not been easy. I've spent most of the time since then in waves of grief, angry about the state of the world, but also feeling helpless to change it.</p>
        <p>Recently I seem to have turned a corner, I am feeling more hopeful and energized. I wanted to catalogue the set of books that I went though, and grouped them roughly by my emotional state when I was most drawn to them. Each of these books helped me in a different phase of my journey.</p>
      </Intro>

      <Grid>
        <div>
          <Title>Shock</Title>
          <p>I started off by voraciously reading worst-case scenario climate books. I learned a lot about climate scenarios, but they left me feeling overwhelmed, paralyzed, and hopeless.</p>
          <BookList>
            <a href="https://bookshop.org/books/the-uninhabitable-earth-life-after-warming/9780525576716">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/4022379/original/9780525576716.jpg" />
            </a>
            <a href="https://bookshop.org/books/the-ends-of-the-world-volcanic-apocalypses-lethal-oceans-and-our-quest-to-understand-earth-s-past-mass-extinctions/9780062364814">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/1923294/original/9780062364814.jpg" />
            </a>
          </BookList>
        </div>

        <div>
          <Title>Anger</Title>
          <p>After I realized how bad things were... I took comfort in reading angry environmentalist books. These are both fine books on their own - but I latched on to the divisive rage within them.</p>
          <BookList>
            <a href="https://bookshop.org/books/desert-solitaire/9780671695880">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/10238206/original/9780671695880.jpg" />
            </a>
            <a href="https://bookshop.org/books/this-land-how-cowboys-capitalism-and-corruption-are-ruining-the-american-west/9780735220997">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/12835087/original/9780735220997.jpg" />
            </a>
          </BookList>
        </div>
        <div>
          <Title>Grief</Title>
          <p>The anger gave way to grief. I mourned the earth that we were losing, and started spending a lot more time outside. The natural world felt precarious, about to blip out of existence, and I wanted to be close to it for whatever time we had left.</p>
          <BookList>
            <a href="https://bookshop.org/books/the-end-of-ice-bearing-witness-and-finding-meaning-in-the-path-of-climate-disruption/9781620975978">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/3913876/original/9781620975978.jpg" />
            </a>
            <a href="https://bookshop.org/books/the-forest-unseen-a-year-s-watch-in-nature/9780143122944">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/4383460/original/9780143122944.jpg" />
            </a>
          </BookList>
        </div>
        <div>
          <Title>Acceptance</Title>
          <p>Except... it's not like the earth is "going anywhere." There will still be ecosystems, even if they are diminished or weakened, and they will still need help. This was a real turning point for me, starting to break out of nihilistic doomism, and start to evaluate next steps forward.</p>
          <BookList>
            <a href="https://bookshop.org/books/timefulness-how-thinking-like-a-geologist-can-help-save-the-world/9780691202631">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/4027544/original/9780691202631.jpg" />
            </a>
            <a href="https://bookshop.org/books/the-mushroom-at-the-end-of-the-world-on-the-possibility-of-life-in-capitalist-ruins-9780691220550/9780691178325">
              <BookCover src="https://images-us.bookshop.org/ingram/9780691220550.jpg" />
            </a>
            <a href="https://bookshop.org/books/all-we-can-save-truth-courage-and-solutions-for-the-climate-crisis/9780593237069">
              <BookCover src="https://images-us.bookshop.org/ingram/9780593237083.jpg" />
            </a>
          </BookList>
        </div>
        <div>
          <Title style={{ color: "#ff0" }}>Integration</Title>
          <p>I reached a turning point - quite recently! - where I started researching how to get involved. What's the latest research on climate solutions? Which am I most passionate about, and how can I have the greatest impact?</p>
          <BookList>
            <a href="https://bookshop.org/books/after-geoengineering-climate-tragedy-repair-and-restoration/9781788730365">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/1243773/original/9781788730365.jpg" />
            </a>
            <a href="https://bookshop.org/books/coral-whisperers-3-scientists-on-the-brink/9780520298859">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/8010523/original/9780520298859.jpg" />
            </a>
            <a href="https://www.goodreads.com/book/show/54814834-under-a-white-sky">
              <BookCover src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1608039192l/54814834.jpg" />
            </a>
          </BookList>
        </div>
        <div>
          <Title>Side Note</Title>
          <p>I've also been reading about how right-wing interest groups have maintained a death-grip on American progress for the past 50 years, and how that has stymied both environmental and social progress in legislation.</p>
          <BookList>
            <a href="https://bookshop.org/books/the-new-climate-war-the-fight-to-take-back-our-planet-aaaf893f-4ff7-4ef0-8e05-3a469aec3957/9781541758230">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/13592991/original/NewClimateWar_front.jpg" />
            </a>
            <a href="https://bookshop.org/books/evil-geniuses-the-unmaking-of-america-a-recent-history-9781984801357/9781984801357">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/14385824/original/9781984801357.jpg" />
            </a>
            <a href="https://bookshop.org/books/virtue-hoarders-the-case-against-the-professional-managerial-class/9781517912253">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/13903724/original/9781517912253.jpg" />
            </a>
          </BookList>
        </div>
        <div style={{ gridColumn: "1 / span 2" }}>
          <Title>Next on the List</Title>
          <p/>
          <BookList>
            <a href="https://bookshop.org/books/braiding-sweetgrass-3e12996d-ea04-4dd2-b9a9-04cfd82f361f/9781571313560">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/4143818/original/9781571313560.jpg" />
            </a>
            <a href="https://bookshop.org/books/the-ministry-for-the-future/9780316300131">
              <BookCover src="https://images-production.bookshop.org/spree/images/attachments/12620072/original/9780316300131.jpg" />
            </a>
          </BookList>
        </div>
      </Grid>
    </div>
  );
}

export default ClimateReading;
