import { useState, useEffect } from "react";
import styled from "styled-components";

import pressList from "./press.json";

const Wrapper = styled.div`
  margin-bottom: 40px;
  color: white;

  a {
    color: white;
  }
  > b {
    font-family: "Quicksand";
    margin-bottom: 6px;
    display: block;
    font-size: 1.25em;
    transform: skew(-20deg);
    span {
      display: inline-block;
      background: white;
      color: black;
      padding: 1px 5px;
      span {
        transform: skew(20deg);
      }
    }
  }
`;
const Table = styled.table`
  border-collapse: collapse;
  td {
    padding: 3px 10px 3px 0;
  }
  td:first-child {
    width: 200px;
  }
  td a:hover {
    background: #ff0;
    color: black !important;
    text-decoration: none;
  }

  @media (max-width: 600px) {
    td:first-child {
      width: auto;
    }
  }
`;

function Press() {
  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 600) setIsSmall(true);
  }, []);

  return (
    <>
      {pressList.map((topic, i) => (
        <Wrapper key={`list${i}`}>
          <b>
            <span>
              <span>{topic.title}</span>
            </span>
          </b>

          <Table>
            <tbody>
              {topic.links.map((l, j) => {
                return isSmall ? SmallRow(l, j) : LargeRow(l, j);
              })}
            </tbody>
          </Table>
        </Wrapper>
      ))}
    </>
  );
}

const SmallRow = (row, j) => (
  <>
    <tr key={`top${j}`}>
      <td>
        <b>{row.publisher}</b>
      </td>
      <td style={{ width: "100px", textAlign: "right", color: "#ccc" }}>
        {row.date}
      </td>
    </tr>
    <tr key={`bottom${j}`}>
      <td colSpan="2" style={{ paddingBottom: "15px" }}>
        <a href={row.link}>{row.title}</a>
      </td>
    </tr>
  </>
);
const LargeRow = (row, j) => (
  <tr key={`l${j}`}>
    <td>
      <b>{row.publisher}</b>
    </td>
    <td style={{ width: "100px", textAlign: "right", color: "#ccc" }}>
      {row.date}
    </td>
    <td>
      <a href={row.link}>{row.title}</a>
    </td>
  </tr>
);

export default Press;
