import styled from "styled-components";

// import artList from "./art.json";
import resumeList from "./resume.json";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
`;
const Job = styled.div`
  margin-bottom: 40px;

  h3 {
    margin-bottom: 10px;
    font-family: "Quicksand";
    color: #ff0;
  }
  h3 a {
    color: #ff0;
    text-decoration: underline dotted #fff;
  }
  h3 a:hover {
    text-decoration-style: solid;
  }
  p {
    color: #ccc;
    margin: 0 0 8px 0;
    font-size: 14px;
  }
`;
// const Art = styled.div`
//   h3 {
//     margin-bottom: 10px;
//     font-family: "Quicksand";
//     color: #ff0;
//   }
//   .item {
//     margin-bottom: 20px;
//   }
//   .flex {
//     display: flex;
//   }
//   .location {
//     color: #ccc;
//     margin: 6px 0 0 20px;
//     font-size: 14px;
//   }
// `;
const ArrowLink = styled.a`
  text-decoration: none;
  font-size: 18px;
  line-height: 10px;
  margin-right: 10px;
  transform: rotate(-90deg);
  display: inline-block;
  color: #ff0;
  &:hover {
    color: #fff;
  }
`;

const HeaderWrapper = styled.a`

    font-family: "Quicksand";
    margin-bottom: 6px;
    display: block;
    font-size: 1.5em;
    transform: skew(-20deg);
    span {
      display: inline-block;
      background: white;
      color: black;
      padding: 1px 5px;
      &:hover {
        background: #ff0;
      }
      span {
        transform: skew(20deg);
      }
    
`;

function Fest() {
  return (
    <>
      {/* <p style={{ fontStyle: "italic", color: "#ccc" }}>
        some might say, her work exists at the intersection of art and
        technology
      </p> */}
      <div style={{ display: "flex", gap: "20px 40px", flexWrap: "wrap" }}>
        <HeaderWrapper href="/rachel-binx-resume.pdf">
          <span>
            <span>Full Resume PDF ➠</span>
          </span>
        </HeaderWrapper>
        <HeaderWrapper href="https://www.linkedin.com/in/rachelbinx">
          <span>
            <span>Linkedin Profile ➠</span>
          </span>
        </HeaderWrapper>
      </div>
      <Wrapper>
        <div>
          {/* <h2>Technology</h2> */}
          {resumeList.map((list, i) => (
            <Job key={`job${i}`}>
              {list.url ? (
                <h3>
                  <a href={list.url}>{list.company}</a>
                </h3>
              ) : (
                <h3>{list.company}</h3>
              )}
              <div style={{ marginLeft: "20px" }}>
                <p>{list.dates}</p>
                {list.title && (
                  <div style={{ fontStyle: "italic" }}>{list.title}</div>
                )}

                {list.clients && (
                  <div style={{ marginLeft: "20px" }}>
                    {list.clients.map((client, j) => (
                      <div
                        key={`client${i}-${j}`}
                        style={{ marginBottom: "5px" }}
                      >
                        {client.url && (
                          <ArrowLink href={client.url}>⇲</ArrowLink>
                        )}
                        {client.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Job>
          ))}
        </div>
        {/* <div>
          <h2>Art</h2>
          {artList.map((type, i) => (
            <Art key={`art${i}`}>
              <h3>{type.title}</h3>
              {type.list.map((item, j) => (
                <div className="item" key={`item${j}`}>
                  <div className="flex">
                    {item.url && <ArrowLink href={item.url}>⇲</ArrowLink>}
                    <span>{item.title}</span>
                  </div>
                  <div className="location">
                    {item.date}
                    {item.location && ` — ${item.location}`}
                  </div>
                </div>
              ))}
            </Art>
          ))}
        </div> */}
      </Wrapper>
    </>
  );
}

export default Fest;
