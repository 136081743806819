import PageWrapper from "./PageWrapper";
import LinkList from "./LinkList";

/*
  scratchpad
  { title: "Tumbleweed Residency" },
        { title: "Backronym Cardgame" }
        { title: "Generative Art Clothing" },
        { title: "Crystal Housewares" },
        { title: "ThingConf" },
        { title: "Crystal Necklace" },
        { title: "Side Project Club" },

  NFT thingy??
  amtrak map
*/

function ArtPractice() {
  const projects = [
    {
      title: "Active Projects",
      projects: [
        { title: "Cloudyvision", siteLink: "https://cloudyvision.rachelbinx.com/" },
        { title: "Climate Reading List", siteLink: "/climate-reading" },
        { title: "Ocean Maps", siteLink: "https://ocean.rachelbinx.com/" },
        { title: "Ambient Videos", siteLink: "/experiment/videos" },
      ]
    },
    {
      title: "Personal Tracking",
      projects: [
        { title: "yes i want a postcard", siteLink: "http://yesiwantapostcard.com/", link: "yespost" },
        { title: "WiFiDiary", siteLink: "https://github.com/binx/WiFiDiary", link: "wifidiary" },
        { title: "Kala UI", link: "kala" },
      ]
    },
    {
      title: "Digital Work",
      projects: [
        { title: "Marfa Tumbleweed", siteLink: "https://marfatumbleweed.com/", link: "marfa-tumbleweed" },
        { title: "gif.local", link: "gif-local" },
        { title: "CLOUDS Documentary", link: "clouds" }
      ]
    },
    {
      title: "Physical Work",
      projects: [
        { title: "California Coastline Ring", link: "ca-coastline" },
        { title: "SF Engraved Mirror", link: "mirror" },
        { title: "Shinjuku/Marfa Blanket" },
        { title: "Mountain Kimono" },
      ]
    }
  ];

  // const thumbnails = [
  //   { title: "Meshu", img: "meshu.jpg"}
  // ]

  // <ThumbnailList list={thumbnails} path="prototype" />

  return (
    <PageWrapper>
      <LinkList list={projects} path="art" />
    </PageWrapper>
  );
}

export default ArtPractice;
