import { useState, useEffect } from "react";
import styled from "styled-components";

import talksList from "./talks.json";

const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  margin-bottom: 40px;
  color: white;

  a { color: white; }
  > b {
    font-family: 'Quicksand';
    margin-bottom: 6px;
    display: block;
    font-size: 1.25em;
    transform: skew(-20deg);
    span {
      display: inline-block;
      background: white; 
      color: black;
      padding: 1px 5px;
      span {
        transform: skew(20deg);  
      }
    }
  }
`;
const Table = styled.table`
  border-collapse: collapse;
  td {
    padding: 5px 10px 5px 0;
  }
  td:first-child { width: 200px; }
  td a:hover {
    background: #ff0;
    color: black !important;
    text-decoration: none;
  }

  @media (max-width: 600px) {
    td:first-child { width: auto; }
    td {
      padding: 2px 10px 2px 0;
    }
  }
`;
const Header = styled.div`
  color: black;
  margin-bottom: 40px;
  display: block;
  font-size: 1.25em;
  transform: skew(-20deg);
  span {
    display: inline-block;
    background: #ff0; 
    padding: 1px 5px;
    span {
      transform: skew(20deg);   
    }
  }

  @media (max-width: 1000px) {
    font-size: 1em;
    span { padding: 1px 7px; }
  }

  @media (max-width: 600px) {
    transform: skew(0deg);
    span span { transform: skew(0deg); }
  }
`;

function Talks() {
  const [isSmall, setIsSmall] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 600)
      setIsSmall(true);
  }, [])
  return (
    <Flex>
      <Header>
        <span><span>
          If you'd like to contact me about a speaking engagement, please email <b>rachelbinx</b> [at] <b>gmail</b> [dot] com
        </span></span>
      </Header>

      {talksList.map((t,i) => (
        <Wrapper key={`list${i}`}>
          <b><span><span>
            {t.title}
          </span></span></b>

          <Table>
            <tbody>
              {t.links.map((l,j) => {
                return isSmall ? SmallRow(l,j) : LargeRow(l,j);
              })}
            </tbody>
          </Table>
        </Wrapper>
      ))}
    </Flex>
  );
}

const SmallRow = (row, j) => (
  <>
    <tr key={`top${j}`}>
      <td>
        <b>{row.event}</b>
      </td>
      <td style={{ color: "#ccc" }}>
        {row.location}
      </td>
      <td style={{ width: "100px", textAlign: "right", color: "#ccc" }}>
        {row.date}
      </td>
    </tr>
    <tr key={`bottom${j}`}>
      <td colSpan="2" style={{ paddingBottom: "15px" }}>
        {row.link ? (
          <a href={row.link}>{row.title}</a>
        ) : (
          row.title
        )}
      </td>
      <td>
        {row.video && <a href={row.video}>video</a>}
      </td>
    </tr>
  </>
)
const LargeRow = (row, j) => (
  <tr key={`l${j}`}>
    <td style={{ width: "200px" }}>
      <b>{row.event}</b>
    </td>
    <td style={{ width: "120px", color: "#ccc" }}>
      {row.location}
    </td>
    <td style={{ width: "100px", textAlign: "right", color: "#ccc" }}>
      {row.date}
    </td>
    <td style={{ padding: "0 20px" }}>
      {row.link ? (
        <a href={row.link}>{row.title}</a>
      ) : (
        row.title
      )}
    </td>
    <td>
      {row.video && <a href={row.video}>video</a>}
    </td>
  </tr>
)

export default Talks;
