import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './index.css';
import App from './App';
import ProductCompanies from "./components/pages/ProductCompanies";
import ArtPractice from "./components/pages/ArtPractice";
import ClientWork from "./components/pages/ClientWork";
import ProjectPage from "./components/pages/ProjectPage";
import Work from "./components/pages/Work";

import Resume from "./components/resume/Resume";
import Press from "./components/press/Press";
import Talks from "./components/talks/Talks";
import About from "./components/about/About";

import ImageSlices from "./components/experiments/ImageSlices";
import Videos from "./components/experiments/Videos";
import ClimateReading from "./components/experiments/ClimateReading";
import Arrows from "./components/experiments/Arrows";
import Arrows1 from "./components/experiments/Arrows1";
import Lines from "./components/experiments/Lines";
import Fest from "./components/experiments/Fest";

import NotFound404 from "./components/NotFound404";
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App>
        <Switch>
          <Route exact path="/" children={<Work />} />

          <Route exact path="/product-companies" children={<ProductCompanies />} />
          <Route path="/product/:name" children={<ProjectPage path="product" />} />

          <Route exact path="/data-visualization" children={<ClientWork />} />
          <Route exact path="/data-visualization/:name" children={<ProjectPage path="data-visualization" />} />

          <Route exact path="/art-practice" children={<ArtPractice />} />
          <Route exact path="/art/:name" children={<ProjectPage path="art" />} />

          <Route exact path="/resume" children={<Resume />} />
          <Route exact path="/talks" children={<Talks />} />
          <Route exact path="/press" children={<Press />} />
          <Route exact path="/about" children={<About />} />
          
          <Route path="/experiment/image-slices">
            <ImageSlices />
          </Route>
          <Route path="/experiment/videos" children={<Videos />} />

          <Route path="/climate-reading" children={<ClimateReading />} />
          <Route path="/arrows" children={<Arrows />} />
          <Route path="/arrows1" children={<Arrows1 />} />
          <Route path="/lines" children={<Lines />} />

          <Route path="/fest" children={<Fest />} />

          <Route component={NotFound404} />
        </Switch>
      </App>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
