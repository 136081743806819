import NavBar from "./components/ui/NavBar";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 40px;
  @media (max-width: 600px) {
    padding: 20px;
  }
`;

function App(props) {
  return (
    <>
      <NavBar />
      <Wrapper>{props.children}</Wrapper>
    </>
  );
}

export default App;
