import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import bg from "./bg.jpg";

const Wrapper = styled.div`
  position: relative;
  background-image: url(${bg});
  background-size: cover;
  background-position: 50%;
  width: 100vw;
  height: 100vh;
  margin: -40px;
`;
const borderAnimation = (props) => keyframes`
  0% {
    top: ${props.top}px;
  }

  50% {
    top: ${props.top + props.to}px;
  }
  100% {
    top: ${props.top}px;
  }
`;
const Box = styled.div`
  // border-radius: 50px;
  position: absolute;
  border-top: 45px dotted;
  border-color: rgba(255,255,255,.3);
  animation-name: ${(props) => borderAnimation(props)};
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
`;

function Arrows() {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    let newGroups = [];
    const num = 40;
    for (let i = 0; i < num; i++) {
      const up = Math.random() > 0.5;
      const dist = Math.random() * 300;
      newGroups.push({
        top: 0 + Math.random() * window.innerHeight,
        left: 0,
        width: window.innerWidth,
        to: up ? dist : -dist,
      });
    }
    setGroups(newGroups);
  }, []);
  //

  return (
    <Wrapper>
      {groups.map((group, i) => (
        <Box
          key={i}
          style={{
            left: group.left,
            width: group.width,
            // height: group.height,
          }}
          top={group.top}
          to={group.to}
        />
      ))}
    </Wrapper>
  );
}

export default Arrows;
