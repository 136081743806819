import { useState, useEffect } from "react";

function Arrows() {
  const [groups, setGroups] = useState([]);

  const size = 700;

  useEffect(() =>{
    let newGroups = [];
    const num = 180;
    for (let i = 0; i < num; i++) {
      
      // 10 60 36
      const period = 36;
      // const x = Math.cos(2*Math.PI/num * i)*100 + i*2;
      const x = Math.cos(2*Math.PI/num * i)*200 + Math.sin(2*Math.PI/period * i)*25;
      const y = Math.sin(2*Math.PI/num * i)*200 + Math.cos(2*Math.PI/period * i)*25;

      const size = Math.sin(2*Math.PI/40 * i)*50;

      newGroups.push({
        size: Math.abs(size) + 20,
        x: x,
        y: y,
        // rotate: (180/num * i) + 180
        // rotate: (Math.sin(2*Math.PI/num * i) > 0) ? 90 : -90
        rotate: (360/num)*i
        
      })
    }
    setGroups(newGroups);
  }, []);
  //

  return (
    <div>
      <svg width={1200} height={size}>
        <g transform={`translate(${size/2}, ${size/2})`}>
        { groups.map((g,i) => (
          <g key={`id${i}`} transform={`translate(${g.x}, ${g.y})`}>
            <text transform={`rotate(${g.rotate})`} fontSize={g.size} fill="white">⟺</text>
          </g>
        ))}
        </g>
      </svg>
    </div>
  );
}

export default Arrows;
