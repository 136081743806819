function PageWrapper({ children }) {
  /*
    <List>
      <h2>Currently In Motion</h2>
      <a href="https://ocean.rachelbinx.com/">Ocean Maps <span>⤇</span></a>
      <Link to="/climate-reading">Climate Reading List<span>⤇</span></Link>
      <Link to="/experiment/videos">Ambient Videos<span>⤇</span></Link>
    </List>
  */

  return (
    <div>
      { children }
    </div>
  );
}

export default PageWrapper;
