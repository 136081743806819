import { useState, useEffect } from "react";
import styled from "styled-components";

import image from "./image.jpg";

const Wrapper = styled.div`
  position: relative;
  width: 590px;
  height: 800px;
  background-image: url(${image});
  background-size: contain;
`;
const Mask = styled.div`
  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    // clip-path: polygon(0 0, 50% 0, 100% 100%, 0% 10%);
    clip-path: polygon(${props => props.clip});
    background-image: url(${image});
    background-size: contain;
    filter: grayscale(1);
  }
`;

function ImageSlices() {
  const [maskClip, setMaskClip] = useState([]);

  const width = 590;
  const height = 800;

  useEffect(() => {
    // clip-path: polygon(0 0, 50% 0, 100% 100%, 0% 10%);
    /*
      random number which side to start on
      rand across that range
      rand +/- greater less than close
      rand slope, chosen greater or less slope for 2nd

      for each pairwise overlap, calculate middle portion and filter overlap
    */

    let maskArray = [];
    let start = Math.random()*.2 * height;
    let slope = .1;

    for (var i = 0; i < 3; i++) {
      const yStart1 = start;
      const yStart2 = Math.random()*20 + 20 + yStart1;
      const slope1 = Math.random()*.2 + slope; // let's see;
      const slope2 = Math.random()*.4 + slope1; // let's see;

    // y − y1 = m(x − x1)
    // y = mx + b

      const clip = `0 ${yStart1}px, 
        100% ${slope1*width + yStart1}px, 
        100% ${slope2*width + yStart2}px, 
        0 ${yStart2}px
      `;
      maskArray.push(clip)
      slope += slope2;
      start = yStart2;
    }

    
    setMaskClip(maskArray)

  }, []);

  return (
    <Wrapper>
      {maskClip.map((m,i) => (
        <Mask clip={m} key={`ss${i}`} />
      ))}
    </Wrapper>
  );
}

export default ImageSlices;
