import PageWrapper from "./PageWrapper";
import LinkList from "./LinkList";

function ClientWork() {
  const projects = [
    {
      title: "Freelance",
      time: "2021-2022",
      projects: [
        { title: "Sofar Ocean Weather Dashboard", link: "sofar-ocean" },
        { title: "Snap Spectacles AR Lens", link: "snap", siteLink: "https://studio.youtube.com/video/2pOxywZjqcg/edit" }
      ]
    },
    {
      title: "Netflix",
      time: "2017-2021",
      projects: [
        { title: "Data Visualization for Content Data Science"}
      ]
    },
    {
      title: "Mapzen",
      time: "2016",
      projects: [
        { title: "Metro Extracts", link: "metro-extracts" },
        { title: "SVG Map Export", siteLink: "https://github.com/binx/svg-export", link: "svg-export" }
      ]
    },
    {
      title: "NASAJPL",
      time: "2015-2016",
      projects: [
        { title: "VORTEX", link: "vortex" }
      ]
    },
    {
      title: "Freelance",
      time: "2012-2015",
      projects: [
        { title: "NYTimes Internal Dashboard", link: "nytimes" },
        { title: "Harvard Library Haystacks", link: "harvard" },
        { title: "HealthyPlan LA", link: "healthyplan" },
        { title: "Fitbit CES Animations", link: "fitbit" },
        { title: "GE Innovation Barometer", link: "ge-innovation" }
      ]
    },
    {
      title: "Stamen",
      time: "2011-2012",
      projects: [
        { title: "MoMA's Talk To Me", siteLink: "https://www.moma.org/interactives/exhibitions/2011/talktome/", link: "talktome" },
        { title: "MTV Award Shows" },
        { title: "Airbnb", link: "airbnb" }
      ]
    }
  ];
  return (
    <PageWrapper>
      <LinkList list={projects} path="data-visualization" />
    </PageWrapper>
  );
}

export default ClientWork;
